/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateOrderInput = {
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
};

export type UpdateOrderInput = {
  venmodescription?: string | null;
  time?: string | null;
  date?: string | null;
  dorm?: string | null;
  roomnumber?: string | null;
  email?: string | null;
};

export type DeleteOrderInput = {
  id?: string | null;
};

export type ModelOrderFilterInput = {
  venmodescription?: ModelStringFilterInput | null;
  time?: ModelStringFilterInput | null;
  date?: ModelStringFilterInput | null;
  dorm?: ModelStringFilterInput | null;
  roomnumber?: ModelStringFilterInput | null;
  email?: ModelStringFilterInput | null;
  and?: Array<ModelOrderFilterInput | null> | null;
  or?: Array<ModelOrderFilterInput | null> | null;
  not?: ModelOrderFilterInput | null;
};

export type ModelStringFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type CreateOrderMutation = {
  __typename: "Order";
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateOrderMutation = {
  __typename: "Order";
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteOrderMutation = {
  __typename: "Order";
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type GetOrderQuery = {
  __typename: "Order";
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type ListOrdersQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    venmodescription: string;
    time: string;
    date: string;
    dorm: string;
    roomnumber: string;
    email: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateOrderSubscription = {
  __typename: "Order";
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateOrderSubscription = {
  __typename: "Order";
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteOrderSubscription = {
  __typename: "Order";
  venmodescription: string;
  time: string;
  date: string;
  dorm: string;
  roomnumber: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateOrder(input: CreateOrderInput): Promise<CreateOrderMutation> {
    const statement = `mutation CreateOrder($input: CreateOrderInput!) {
        createOrder(input: $input) {
          __typename
          venmodescription
          time
          date
          dorm
          roomnumber
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderMutation>response.data.createOrder;
  }
  async UpdateOrder(input: UpdateOrderInput): Promise<UpdateOrderMutation> {
    const statement = `mutation UpdateOrder($input: UpdateOrderInput!) {
        updateOrder(input: $input) {
          __typename
          venmodescription
          time
          date
          dorm
          roomnumber
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderMutation>response.data.updateOrder;
  }
  async DeleteOrder(input: DeleteOrderInput): Promise<DeleteOrderMutation> {
    const statement = `mutation DeleteOrder($input: DeleteOrderInput!) {
        deleteOrder(input: $input) {
          __typename
          venmodescription
          time
          date
          dorm
          roomnumber
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderMutation>response.data.deleteOrder;
  }
  async GetOrder(id: string): Promise<GetOrderQuery> {
    const statement = `query GetOrder($id: ID!) {
        getOrder(id: $id) {
          __typename
          venmodescription
          time
          date
          dorm
          roomnumber
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderQuery>response.data.getOrder;
  }
  async ListOrders(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrdersQuery> {
    const statement = `query ListOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            venmodescription
            time
            date
            dorm
            roomnumber
            email
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersQuery>response.data.listOrders;
  }
  OnCreateOrderListener: Observable<OnCreateOrderSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrder {
        onCreateOrder {
          __typename
          venmodescription
          time
          date
          dorm
          roomnumber
          email
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnCreateOrderSubscription>;

  OnUpdateOrderListener: Observable<OnUpdateOrderSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrder {
        onUpdateOrder {
          __typename
          venmodescription
          time
          date
          dorm
          roomnumber
          email
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnUpdateOrderSubscription>;

  OnDeleteOrderListener: Observable<OnDeleteOrderSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrder {
        onDeleteOrder {
          __typename
          venmodescription
          time
          date
          dorm
          roomnumber
          email
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnDeleteOrderSubscription>;
}
