import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Order } from '../models/order.model';
import { APIService } from '../API.service';
import { v4 as uuid } from 'uuid';
import { ConfirmationMobileModalPage } from '../confirmation-mobile-modal/confirmation-mobile-modal.page';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.page.html',
  styleUrls: ['./confirmation-modal.page.scss'],
})
export class ConfirmationModalPage implements OnInit {

  order: Order;
  cost: any;
  currentdate: String = new Date().toISOString();
  dataReturned:any;
  constructor(private modalController: ModalController,
    private navParams: NavParams,  private apiService: APIService) { }


  ngOnInit() {
    console.table(this.navParams);
    this.order = this.navParams.data.order;
    this.cost = 8;
  }

  checkIfAllDataFilled(): boolean {
    if (this.order.roomnumber== null) {
      return false;
    }
    if (this.order.dorm== null) {
      return false;
    }
    if (this.order.email== null) {
      return false;
    }
    else {
      return true;
    }
  }

  checkout() {
    if (this.checkIfAllDataFilled() == true) {
      this.order.date = 'test';
      this.order.time = 'test';
      this.order.venmodescription = String(this.order.dorm+ this.order.roomnumber+ ' '+ uuid());
      this.openMobileModal(this.order);
      this.createOrder(this.order);
    }
  }

  createOrder(order) {
    this.apiService.CreateOrder({
      dorm : order.dorm,
      date : order.date,
      time : order.time,
      email : order.email,
      roomnumber : order.roomnumber,
      venmodescription : order.venmodescription,
    })
    console.log(order+ ' has been submitted to the server');
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }
  
  async openMobileModal(order: Order) {
    console.log('the order being transfered is '+order);
    const modal = await this.modalController.create({
      component: ConfirmationMobileModalPage,
      componentProps: {
        "order": order
      },
      cssClass: []
    });
 
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
 
    return await modal.present();
  }

}
