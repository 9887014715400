import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Order } from '../models/order.model';
@Component({
  selector: 'app-confirmation-mobile-modal',
  templateUrl: './confirmation-mobile-modal.page.html',
  styleUrls: ['./confirmation-mobile-modal.page.scss'],
})
export class ConfirmationMobileModalPage implements OnInit {

  order: Order;
  constructor(    private modalController: ModalController,
    private navParams: NavParams) {
      this.dynamicColor = 'secondary';
     }
    private dynamicColor: string

  ngOnInit() {
    console.table(this.navParams);
    this.order = this.navParams.data.order;
    
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

}
